import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch, connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { profileSlice } from "../../../../app/redux/profile/profileSlice"
import { firebaseApp, initializeFirestore } from "../../../../firebase"
import { getFirestore } from "firebase/firestore"

import PersonIcon from "@material-ui/icons/Person"
import BusinessIcon from "@material-ui/icons/Business"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import { Dropdown } from "./Dropdown"

import * as authRedux from "../../../modules/Auth/_redux/authRedux"
import { injectIntl } from "react-intl"
import { useCustomersSnapshot } from "./_partials/useCustomerSnapshot"
import { fetchCustomerDoc } from "../../../_utils/fetchCustomerDoc"
import { fetchSiteDoc } from "../../../_utils/fetchSiteDoc"
import { fetchFloorPlanDoc } from "../../../_utils/fetchFloorPlanDoc"

const db = getFirestore(firebaseApp)

const { actions } = profileSlice

const LABEL_CUSTOMER = "Customer"
const LABEL_SITE = "Location"
const LABEL_ROLE = "Role"
const LABEL_FLOOR_PLAN = "Floor Plan"
const CUSTOMER_MENU_ITEM_VALUE = "customerInfo.name"
const LABEL_REPORTING = "reporting"

function HeaderSelections(props) {
	const dispatch = useDispatch()
	const location = useLocation()

	const { userClaims, floorPlans, currentCustomer, currentSite, currentFloorPlan } = useSelector(
		state => ({
			userClaims: state.auth.claims,
			floorPlans: state.basePage.floorPlans,
			currentCustomer: state.profile.currentCustomer,
			currentSite: state.profile.currentSite,
			currentFloorPlan: state.profile.currentFloorPlan
		}),
		shallowEqual
	)

	const [customers, setCustomers] = useState()
	const [selectedCustomer, setSelectedCustomer] = useState(currentCustomer?.id || null)
	const [firestore, setFirestore] = useState(null)

	const [selectedSite, setSelectedSite] = useState(currentSite?.id || null)
	const [selectedFloorPlan, setSelectedFloorPlan] = useState(currentFloorPlan?.id || null)
	const [isReporting, setIsReporting] = useState(false)

	const [loadingCustomers, setLoadingCustomers] = useState(false)
	const [loadingSites, setLoadingSites] = useState(false)
	const [loadingFloorPlans, setLoadingFloorPlans] = useState(false)

	useEffect(() => {
		if (currentCustomer) {
			setSelectedCustomer(currentCustomer?.id)

			if (currentSite) {
				setSelectedSite(currentSite?.id)
				setSelectedFloorPlan(null)
			}
		}
	}, [])

	useEffect(() => {
		if (!userClaims) return

		setLoadingCustomers(true)

		const unsubscribe = useCustomersSnapshot(db, userClaims, setCustomers, setLoadingCustomers)

		return () => {
			if (unsubscribe) unsubscribe()
		}
	}, [userClaims])

	useEffect(() => {
		if (!customers || customers.length === 0) return

		let customerSelected = selectedCustomer ?? customers[0]?.id

		if (!selectedCustomer) {
			setSelectedCustomer(customerSelected)
		}

		const findCustomer = customers.find(val => val.id === customerSelected)

		const fetchAndInitialize = async () => {
			const firestoreInstance = await initializeFirestore(findCustomer?.databaseId)
			if (firestoreInstance) {
				setFirestore(firestoreInstance)
				const customerData = await fetchCustomerDoc(findCustomer, userClaims, firestoreInstance)
				dispatch(actions.customerSelected(customerData))
			}
		}

		fetchAndInitialize()
	}, [selectedCustomer, customers])

	useEffect(() => {
		setIsReporting(location.pathname.includes(LABEL_REPORTING))
	}, [location.pathname])

	useEffect(() => {
		if (!userClaims || !selectedCustomer) return

		const fetchSiteData = async () => {
			setLoadingSites(true)

			try {
				const siteData = await fetchSiteDoc(selectedSite, firestore)

				if (siteData) {
					dispatch(actions.siteSelected(siteData))
				} else {
					dispatch(actions.siteSelected(null))
				}
			} catch (error) {
				console.error(error)
			} finally {
				setLoadingSites(false)
			}
		}

		fetchSiteData()
	}, [selectedSite, userClaims, firestore, dispatch])

	useEffect(() => {
		if (
			!floorPlans ||
			floorPlans.length === 0 ||
			!selectedCustomer ||
			!selectedSite ||
			selectedFloorPlan
		)
			return

		const firstFloorPlanId = floorPlans[0]?.id

		if (!selectedFloorPlan) {
			setSelectedFloorPlan(firstFloorPlanId)
		}
	}, [floorPlans])

	useEffect(() => {
		let isMounted = true

		const fetchFloorPlanData = async () => {
			setLoadingFloorPlans(true)

			const floorPlanData = await fetchFloorPlanDoc(
				selectedFloorPlan,
				currentSite?.id,
				firestore
			)

			if (!floorPlanData) {
				if (isMounted) {
					dispatch(actions.floorPlanSelected(null))
				}
			} else {
				if (isMounted) {
					dispatch(actions.floorPlanSelected(floorPlanData))
				}
			}

			if (isMounted) {
				setLoadingFloorPlans(false)
			}
		}

		fetchFloorPlanData()

		return () => {
			isMounted = false
		}
	}, [selectedFloorPlan])

	const handleCustomerChange = value => {
		setSelectedCustomer(prev => {
			if (prev === value) return prev

			setSelectedSite(null)
			setSelectedFloorPlan(null)

			return value
		})
	}

	const handleSiteChange = value => {
		if (value === "") {
			dispatch(actions.siteSelected(null))
		}

		setSelectedSite(value)
		setSelectedFloorPlan(null)
	}

	const handleFloorPlanChange = value => {
		setSelectedFloorPlan(value)
	}

	const handleRoleChange = value => {
		if (!userClaims.isSuper) return

		let claims = { ...userClaims }

		switch (value) {
			case "msp":
				claims.profileLvl = 3
				break
			case "admin":
				claims.profileLvl = 2
				break
			case "user":
				claims.profileLvl = 1
				break
			default:
				claims = {
					...userClaims,
					profileLvl: authRedux.getProfileLvl(userClaims)
				}
				break
		}

		props.setUserClaims(claims)
	}

	return (
		<div className="header-selections">
			{userClaims.profileLvl >= 3 && (
				<Dropdown
					label={LABEL_CUSTOMER}
					icon={BusinessIcon}
					items={customers}
					value={selectedCustomer}
					onChange={e => handleCustomerChange(e.target.value)}
					loading={loadingCustomers}
					menuItemValue={CUSTOMER_MENU_ITEM_VALUE}
				/>
			)}
			<Dropdown
				dropdownForSiteSelection={true}
				label={LABEL_SITE}
				icon={LocationOnIcon}
				items={currentCustomer?.sites}
				value={selectedSite}
				onChange={e => handleSiteChange(e.target.value)}
				loading={loadingSites}
				isHeaderLocation={true}
			/>
			{!isReporting && userClaims.profileLvl >= 1 && (
				<Dropdown
					label={LABEL_FLOOR_PLAN}
					icon={ClearAllIcon}
					items={floorPlans}
					value={selectedFloorPlan}
					onChange={e => handleFloorPlanChange(e.target.value)}
					loading={loadingFloorPlans || loadingSites}
				/>
			)}
			{userClaims.isSuper && (
				<Dropdown
					label={LABEL_ROLE}
					icon={PersonIcon}
					items={[
						{
							name: "Original role",
							value: "",
							id: ""
						},
						{
							name: "MSP",
							value: "msp",
							id: "msp"
						},
						{
							name: "Admin",
							value: "admin",
							id: "admin"
						},
						{
							name: "User",
							value: "user",
							id: "user"
						}
					]}
					value={
						userClaims.profileLvl === 3
							? "msp"
							: userClaims.profileLvl === 2
							? "admin"
							: userClaims.profileLvl === 1
							? "user"
							: ""
					}
					onChange={e => handleRoleChange(e.target.value)}
				/>
			)}
		</div>
	)
}

export default injectIntl(connect(null, authRedux.actions)(HeaderSelections))
