import React from "react"
import { doc, collection, getDoc } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import { storage } from "../../firebase"
import { ERRORS } from "../_enums/errors"

const SITES_STRING = "Sites"
const FLOOR_PLANS_STRING = "FloorPlans"

export const fetchFloorPlanDoc = async (floorPlanId, siteId, db) => {
	try {
		const floorPlanDoc = doc(
			collection(db, SITES_STRING, siteId, FLOOR_PLANS_STRING),
			floorPlanId
		)
		const docSnap = await getDoc(floorPlanDoc)

		if (!docSnap) return null

		const floor = docSnap.data()

		let imageUrl = ""
		if (floor.imagePath) {
			try {
				imageUrl = await getDownloadURL(ref(storage, floor.imagePath))
			} catch (error) {
				console.error(ERRORS.FETCH_ERRORS.IMAGE_URL, error)
			}
		}

		const newFloorPlan = {
			id: docSnap.id,
			...floor,
			imageUrl
		}

		return newFloorPlan
	} catch (error) {
		console.error(ERRORS.FETCH_ERRORS.FLOOR_PLAN_DOCUMENT, error)
		return null
	}
}
