import React from "react"
import { collection, onSnapshot, query, where } from "firebase/firestore"

import { ERRORS } from "../../../../_enums/errors"
import { COLLECTIONS, QUERY_CONSTRAINTS } from "../../../../_enums/firestoreConstants"

export function useCustomersSnapshot(db, userClaims, setCustomers, setLoadingCustomers) {
	console.log("🚀 ~ useCustomersSnapshot ~ userClaims:", userClaims)
	try {
		const customersCollection = collection(db, COLLECTIONS.CUSTOMERS)
		let customersQuery

		if (userClaims.profileLvl === 3) {
			customersQuery = query(
				customersCollection,
				where(
					QUERY_CONSTRAINTS.WHERE.TENANT_ID,
					QUERY_CONSTRAINTS.OPERATORS.IN,
					userClaims.customerIds
				)
			)
		} else if (userClaims.profileLvl < 3) {
			customersQuery = query(
				customersCollection,
				where(
					QUERY_CONSTRAINTS.WHERE.FIRESTORE_DEFAULT_DOCUMENT_ID,
					QUERY_CONSTRAINTS.OPERATORS.EQUAL,
					userClaims.customerId
				)
			)
		} else {
			//isSuper
			customersQuery = query(customersCollection)
		}

		const unsubscribe = onSnapshot(
			customersQuery,
			snapshot => {
				const data = snapshot.docs.map(doc => ({
					...doc.data(),
					id: doc.id
				}))
				setCustomers(data)
				setLoadingCustomers(false)
			},
			error => {
				console.error(ERRORS.FETCH_ERRORS.CUSTOMER_DOCUMENT, error)
				setCustomers([])
				setLoadingCustomers(false)
			}
		)

		return unsubscribe
	} catch (error) {
		console.error(ERRORS.FETCH_ERRORS.CUSTOMER_DOCUMENT, error)
		setLoadingCustomers(false)
		return null
	}
}
