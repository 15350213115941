import React from "react"
import { doc, getDoc } from "firebase/firestore"
import { ERRORS } from "../_enums/errors"

const SITES_STRING = "Sites"

export const fetchSiteDoc = async (siteId, db) => {
	try {
		const siteDoc = doc(db, SITES_STRING, siteId)
		const docSnap = await getDoc(siteDoc)

		if (!docSnap) return

		const newSite = { id: docSnap.id, ...docSnap.data() }

		return newSite
	} catch (error) {
		console.error(ERRORS.FETCH_ERRORS.SITE_DOCUMENT, error)
		return null
	}
}
