import React from "react"
import { ListSubheader } from "@material-ui/core"

const DropdownSubHeader = ({ isHeaderLocation, text }) => {
	if (!isHeaderLocation) return null

	return (
		<ListSubheader
			disableSticky
			style={{
				fontSize: "13px",
				fontWeight: 500,
				userSelect: "none",
				pointerEvents: "none"
			}}
		>
			{text}
		</ListSubheader>
	)
}

export default DropdownSubHeader
