import React from "react"
import { doc, getDoc } from "firebase/firestore"
import { ERRORS } from "../_enums/errors"

const CUSTOMERS_STRING = "Customers"

export const fetchCustomerDoc = async (customer, userClaims, firestore) => {
	try {
		const customerDoc = doc(firestore, CUSTOMERS_STRING, customer.id)
		const docSnap = await getDoc(customerDoc)

		if (!docSnap.exists()) return

		const realCustomer = docSnap.data()

		const newCustomer = {
			...realCustomer,
			id: customer.id,
			sites: realCustomer?.sites?.filter(
				val =>
					!val.disabled &&
					(userClaims.profileLvl >= 3 ||
						(userClaims.sites && userClaims.sites.includes(val.id)))
			)
		}

		return newCustomer
	} catch (error) {
		console.error(ERRORS.FETCH_ERRORS.CUSTOMER_DOCUMENT, error)
		return null
	}
}
