export const COLLECTIONS = {
	CUSTOMERS: "Customers",
	MESSAGES: "Messages",
	SITES: "Sites",
	NODES: "Nodes",
	SENSORS_POSITION: "SensorsPosition",
	ANCHORS_POSITION: "AnchorsPosition"
}

export const MESSAGING = {
	TYPES: {
		MESSAGE: "message",
		ANNOUNCEMENT: "announcement"
	}
}

export const QUERY_CONSTRAINTS = {
	WHERE: {
		FIRESTORE_DEFAULT_DOCUMENT_ID: "__name__",
		TYPE: "type",
		DATE: "date",
		ERASE: "erase",
		UID_TO: "uidTo",
		NODE_TYPE: "nodeType",
		NODE_NAME: "node_name",
		STATUS: "status",
		TENANT_ID: "tenantID",
		CUSTOMER_TYPE: "customerType",
		HEALTHCARE: "healthcare",
		CONSTRUCTION: "construction"
	},
	ORDER_BY: {
		DATE: "date",
		ASC: "asc",
		DESC: "desc",
		CREATED_DATA: "created.date"
	},
	OPERATORS: {
		EQUAL: "==",
		NOT_EQUAL: "!=",
		GREATER_THAN: ">",
		GREATER_THAN_OR_EQUAL: ">=",
		LESS_THAN: "<",
		LESS_THAN_OR_EQUAL: "<=",
		IN: "in"
	},
	VALUES: {
		FALSE: false,
		TRUE: true,
		MESSAGES: "messages",
		SENSOR: "sensor",
		ONLINE: "online"
	}
}
