import { initializeApp } from "firebase/app"
import { getFirestore, Firestore, collection, getDocs } from "firebase/firestore"

import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"
import config from "./config"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/messaging" // Import the messaging module //

export const firebaseAppOld = firebase.initializeApp(config.firebaseConfig)
export const firestoreOld = firebase.firestore()

export const firebaseApp = initializeApp(config.firebaseConfig)
// export const database = getDatabase(firebaseApp)
// console.log("🚀 ~ database:", database)
//? returns a tenantId after sign in, and in all low key
//instantiate auth
export const auth = getAuth(firebaseApp)

// export const firestore = getFirestore(firebaseApp, "projedomus-km93g") //, "projedomus-km93g"

// export let firestore = getFirestore(firebaseApp)
export let firestore = getFirestore()

export async function initializeFirestore(tenantId) {
	const id = tenantId?.toLowerCase() || null
	let auxFirestore = null

	try {
		auxFirestore = id ? getFirestore(firebaseApp, id) : getFirestore(firebaseApp)
		firestore = auxFirestore
		return auxFirestore
	} catch (error) {
		console.error("Error initializing Firestore:", error)
		return null
	}
}

async function isBDValid(firestoreInstance) {
	// console.log("🚀 ~ isBDValid ~ firestoreInstance:", firestoreInstance)
	try {
		const customersCollection = collection(firestoreInstance, "Customers")
		const snapshot = await getDocs(customersCollection)
		const data = snapshot.docs.map(doc => ({
			...doc.data(),
			id: doc.id
		}))
		// console.log("🚀 ~ data ~ data:", data)

		return data.length > 0
	} catch (error) {
		console.error("Error validating Firestore database:", error)
		return false
	}
}

export const storage = firebase.storage()

// Delete storage and change this to storage when all app is using v9 version
export const storageV9 = getStorage(firebaseApp)
// export const messaging = isSupported() ? getMessaging(firebaseApp) : null
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

export const createUserProfileDocument = async (userAuth, additionalData) => {
	if (!userAuth) return

	const userRef = firestore.doc(`Login/${userAuth.uid}`)

	const snapShot = await userRef.get()

	if (!snapShot.exists) {
		const { displayName, email } = userAuth
		const createdAt = new Date()
		try {
			await userRef.set({
				displayName,
				email,
				createdAt,
				...additionalData
			})
		} catch (error) {
			console.log("error creating user", error.message)
		}
	}

	return userRef
}

export const addDocToCollection = async (collectionKey, data) => {
	const docRef = firestore.collection(collectionKey).doc()
	return await docRef.set({ ...data })
}

//Custom claims teste
export async function createCustomer(data) {
	// console.log("Data: " + data)

	const makeAdmin = firebase.functions().httpsCallable("addAdminRole")

	makeAdmin({ email: data }).then(result => {
		console.log(result)
	})

	return data
}

export async function createUser(user) {
	console.log("CHEGOU ao index file -> Data: " + user)

	const createUser = firebase.functions().httpsCallable("createUser")

	createUser(user).then(result => {
		console.log(result)
	})

	//   return data

	// 	const { data } = await createUser(user)

	// 	if (!data.uid) {
	// 		console.log(data)
	// 		throw Error(data.errorInfo?.message)
	// 	}

	// 	/*const customerClaims = customer.customer
	//     ? { [customer.profile]: true, customer: customer.customer }
	//     : { [customer.profile]: true }*/

	// 	const customerClaims = {
	// 		[user.admins]: true,
	// 		customerId: user.customer
	// 	}

	// 	await firestore.doc(`user-claims/${data.uid}`).set(customerClaims)

	// 	return { ...data, customClaims: { ...customerClaims } }
}

export function findCustomers(queryParams) {
	var functions = firebase.app().functions("europe-west2")
	const listAllUsers = functions.httpsCallable("listAllUsers")

	return listAllUsers({
		tenantID: queryParams.tenantID,
		usersAmount: queryParams.pageSize,
		nextPageToken: queryParams.pageToken
			? queryParams.pageToken[queryParams.pageNumber - 1]
			: queryParams.pageToken
	})
		.then(response => {
			return {
				data: response.data.data,
				pageToken: response.data.pageToken,
				totalCount: response.data.totalCount
			}
		})
		.then(datas => ({
			entities: datas.data,
			totalCount: datas.totalCount,
			nextPageToken: datas.pageToken
		}))
}

export function listEmployees(limit, search, searchField, customerId) {
	console.log(customerId)
	return firestore
		.collection("Customers")
		.doc(customerId)
		.collection("DevicesWiFi_v3")
		.where(searchField, ">", "")
		.where(searchField, ">=", search)
		.where(searchField, "<=", `${search}\uf8ff`)
		.orderBy(searchField)
		.limit(limit)
		.get()
}

export function listNextEmployees(last, limit, search, searchField, customerId) {
	console.log(last.id)
	return firestore
		.collection("Customers")
		.doc(customerId)
		.collection("DevicesWiFi_v3")
		.where(searchField, ">", "")
		.where(searchField, ">=", search)
		.where(searchField, "<=", `${search}\uf8ff`)
		.orderBy(searchField)
		.startAfter(last)
		.limit(limit)
		.get()
}

export function listPreviousEmployees(first, limit, search, searchField, customerId) {
	return firestore
		.collection("Customers")
		.doc(customerId)
		.collection("DevicesWiFi_v3")
		.where(searchField, ">", "")
		.where(searchField, ">=", search)
		.where(searchField, "<=", `${search}\uf8ff`)
		.orderBy(searchField)
		.endBefore(first)
		.limitToLast(limit)
		.get()
}

export function listLastEmployees(limit, search, searchField, customerId) {
	return firestore
		.collection("Customers")
		.doc(customerId)
		.collection("DevicesWiFi_v3")
		.where(searchField, ">", "")
		.where(searchField, ">=", search)
		.where(searchField, "<=", `${search}\uf8ff`)
		.orderBy(searchField)
		.limitToLast(limit)
		.get()
}

export async function changeEmployeesData(data) {
	await firestore
		.collection("Customers")
		.doc("C000100")
		.collection("DevicesWiFi_v3")
		.doc(data.clientMac)
		.set(
			{
				optIn: data.optIn || false,
				trackDevice: data.trackDevice || false,
				user: {
					realName: data.realName || "",
					emailAddress: data.emailAddress || "",
					username: data.username || "",
					deviceName: data.deviceName || "",
					deviceModel: data.deviceModel || ""
				}
			},
			{ merge: true }
		)

	if (data.trackDevice === false) {
		await firestore
			.collection("Customers")
			.doc("C000100")
			.collection("DevicesWiFi_v3")
			.doc(data.clientMac)
			.set(
				{
					loc: {
						currLocation: ""
					}
				},
				{ merge: true }
			)
	}
}

export const streamTotalEmployees = observer => {
	return firestore
		.collection("Customers")
		.doc("C000100")
		.collection("Summary")
		.doc("userImport")
		.onSnapshot(observer)
}
