import React from "react"
import { FormControl, MenuItem, Select } from "@material-ui/core"
import DropdownSubHeader from "./DropdownSubHeader"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"
function getNestedValue(obj, path) {
	return path?.split(".")?.reduce((acc, key) => acc && acc[key], obj)
}

const ACTIVE_SITES = "Active Sites"
const COMPLETED_SITES = "Completed Sites"

export const Dropdown = ({
	label,
	icon: Icon,
	items,
	value,
	onChange,
	loading,
	placeholder = `Select ${label}`,
	menuItemValue = "name",
	isHeaderLocation = false,
	dropdownForSiteSelection = false
}) => {
	const showCompletedList = !!(dropdownForSiteSelection && items?.some(item => item.siteFinished))

	return (
		<div style={{ marginLeft: "10px" }}>
			<Icon />
			<FormControl size="small" style={{ marginLeft: "5px" }}>
				<Select
					disabled={loading}
					displayEmpty
					disableUnderline
					value={value || ""}
					onChange={onChange}
					MenuProps={{
						PaperProps: { style: { maxHeight: 48 * 3 + 8, width: 250 } }
					}}
				>
					<MenuItem value="" disabled={isHeaderLocation && items?.length > 2 ? false : true}>
						{placeholder}
					</MenuItem>

					<DropdownSubHeader isHeaderLocation={isHeaderLocation} text={ACTIVE_SITES} />

					{items
						?.filter(item => !isHeaderLocation || !item.siteFinished)
						.map((item, index) => {
							const menuItemLabel =
								getNestedValue(item, menuItemValue) || item[menuItemValue]

							return (
								<MenuItem key={item.id || index} value={item.id}>
									{menuItemLabel || ""}
								</MenuItem>
							)
						})}

					{showCompletedList && (
						<>
							<DropdownSubHeader
								isHeaderLocation={isHeaderLocation}
								text={COMPLETED_SITES}
							/>

							{isHeaderLocation &&
								items
									?.filter(item => !isHeaderLocation || item.siteFinished)
									.map((item, index) => {
										const menuItemLabel =
											getNestedValue(item, menuItemValue) || item[menuItemValue]

										return (
											<MenuItem key={item.id || index} value={item.id}>
												{menuItemLabel || ""}
											</MenuItem>
										)
									})}
						</>
					)}
				</Select>
			</FormControl>
			{loading && <ModalProgressBar />}
		</div>
	)
}
